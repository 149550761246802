.webPage {
  /* text-align: center; */
  background-color: #000000;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Spotlight {
  /* display: inline-flex; */
  /* flex-direction: row; */
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 5%;
}

.headShot {
  height: 40vmin;
  pointer-events: none;
  border-radius: 50%;
  float: left;
  width: auto;

  /* padding-right: 2%; */
}

.headShotName {
  display: flex;
  justify-content: right;
  /* align-items: center; */
  font-size: 400%;
  padding-left: 7%;

  /* padding-left: 2%; */
  /* float: right;  */
  /* overflow: hidden; */
  /* width: auto;  */
}

.headShotDescriptor {
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-profile-spin {
  from {
    /* transform: rotate(0deg); */
  }
  to {
    transform: scale(0.3) translate(-100%, -100%);
  }
}

.fa-bounce {
  animation: fa-bounce 2s infinite linear !important;
}

.html {
  scroll-behavior: smooth;
  scroll-snap-type: both mandatory;
}

.typing-animation {
  display: inline-block;
  width: 3px;
  height: 1.5rem;
  margin-left: 10px;
  margin-bottom: 2px;
}

.scroll-down {
  height: 50px;
  width: 30px;
  border: 2px solid white;
  position: relative;
  margin-left: 50%;
  margin-right: 50%;
  /* left: 50%; */
  /* bottom: 20px; */
  border-radius: 50px;
  cursor: pointer;
}
.scroll-down::before,
.scroll-down::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  height: 10px;
  width: 10px;
  transform: translate(-50%, -100%) rotate(45deg);
  border: 2px solid white;
  border-top: transparent;
  border-left: transparent;
  animation: scroll-down 1s ease-in-out infinite;
}
.scroll-down::before {
  top: 30%;
  animation-delay: 0.5s;
  /* animation: scroll-down 1s ease-in-out infinite; */
}

@keyframes scroll-down {
  0% {
    /* top:20%; */
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    top: 90%;
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-slide-in {
  animation: slideIn 0.3s forwards;
}

.animate-slide-out {
  animation: slideOut 0.3s forwards;
}

html,
body {
  overflow-x: hidden;
}
